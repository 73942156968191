import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';
import OL from '@rotaready/frecl/build/OL';
import TextListItem from '@rotaready/frecl/build/TextListItem';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Pizza Pilgrims Customer Story - Rotaready"
          description="Since getting started with Rotaready, Pizza Pilgrims have been able to better deploy 54 hours every week and achieved an ROI of 485%. Here's how..."
          url="customer-stories/hospitality-pizza-pilgrims"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Margherita pizza"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="Pizza Pilgrims logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Since getting started with Rotaready, Pizza Pilgrims have been able to better deploy 54 hours every week and achieved an ROI of 485%. Here's how..." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="19" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="340" />
              <StatSubtitle text="staff" />
            </StatItem>

            <StatItem>
              <StatHeadline text="485%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="54 hours" />
              <StatSubtitle text="better deployed each week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Pizza Pilgrims" />
              </Content>

              <Content>
                <TextBlock text="Founded in 2012 by brothers Thom and James Elliot, Pizza Pilgrims started out as a pizza van on Soho's Berwick Street. Now with 19 sites across the country, the brothers' Neapolitan pizza business has become renowned across the UK." />
              </Content>

              <Content>
                <TextBlock text="Alongside producing exceptional pizza, ensuring customer happiness has always been a top priority for the business. A big player in making this happen (exceptional pizza aside) has been to make sure the business is run efficiently and that the right people are in the right place and at the right time. Pizza Pilgrims has always understood the importance of tech in achieving this." />
              </Content>

              <Content>
                <TextBlock text="In the early years the pizzeria implemented a workforce management solution to help them schedule and manage staff time. But it soon became apparent that the existing platform wasn't advancing in the way Pizza Pilgrims needed it to in order to support their growing business. The dated rota software was rigid, lacked transparency in its calculations, required onerous manual input and human support left much to be desired." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Time for change" />
              </Content>

              <Content>
                <TextBlock text="So, in May 2020 Pizza Pilgrims set about finding a new and more progressive staff scheduling solution; one that would eradicate all of the frustrations they were currently facing." />
              </Content>

              <Content>
                <TextBlock text="After discussing alternatives and seeking recommendations from their casual dining peers, the Pizza Pilgrims team turned to Rotaready to help them take their staff scheduling to the next level." />
              </Content>

              <Content>
                <Blockquote
                  text="We were looking for rota software that was user-friendly, cloud-based and gave us transparency over our data. But primarily we needed a solution that was accurate and innovative! One that could grow with our business. Rotaready ticked every box."
                  attribution="Sophie Gilchriest, Finance Director"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Onwards and upwards" />
              </Content>

              <Content>
                <TextBlock text="Within one month of making the decision to move to Rotaready, all 13 of Pizza Pilgrims' sites were up and running with the new rota software. A full migration from Rotaready's predecessor was carefully and diligently completed, including a bespoke configuration, full data import and employee training." />
              </Content>

              <Content>
                <Blockquote
                  text="The migration process was brilliant. The team at Rotaready were all over it; swift in answering our questions and resolving any teething issues we had."
                  attribution="Sophie Gilchriest, Finance Director"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Chef preparing pizza"
              />
            </Section>

            <Section>
              <Content>
                <TextBlock text="Pizza Pilgrims have been benefiting from Rotaready's intelligent rota software, across all areas of the business, ever since. Managers love how easy building and sharing rotas has become. Plus the increased visibility and accuracy they now have with their live commercial performance metrics. Life has become easier for those in Head Office too, through the immediate availability of insightful reports and streamlined processes to keep things moving smoothly." />
              </Content>

              <Content>
                <TextBlock text="Since getting started with Rotaready, Pizza Pilgrims have opened six new sites and worked closely with the Rotaready team to set up real-time integrations with their EPOS and analytics systems." />
              </Content>

              <Content>
                <Blockquote
                  text="Opening new sites and enabling integrations with Rotaready has been effortless, stress free and generally seamless. We're looking forward to integrating Rotaready with our payroll system in the coming months."
                  attribution="Sophie Gilchriest, Finance Director"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="The stars of the show" />
              </Content>

              <Content>
                <TextBlock text="Pizza Pilgrims are using the majority of Rotaready's features but everyone has their favourites. Here are the pizzerias' top three things they like about Rotaready..." />
              </Content>

              <Content>
                <TextBlock>
                  <OL size="lg" uistyle="normal" iconUistyle="primary">
                    <TextListItem text="The human support" />
                    <TextListItem text="Transparency and accuracy of data" />
                    <TextListItem text="Ease and efficiency of rota preparation" />
                  </OL>
                </TextBlock>
              </Content>
            </Section>

            <Section>
              <PopQuote text={`"For us, Rotaready's support stands out above everything else. We know we're always speaking to a human being, who will give us an instant response and quick resolution."`} />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="Even if something drops off my list, the Rotaready team follow-up to make sure my issue has been resolved. The level of support is truly brilliant!"
                  attribution="Sophie Gilchriest, Finance Director"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Cocktail bar inside Pizza Pilgrims venue"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="What's next?" />
              </Content>

              <Content>
                <TextBlock text="Next on the agenda for Pizza Pilgrims is to explore an integration with their applicant tracking software to further automate the onboarding of new joiners." />
              </Content>

              <Content>
                <TextBlock text="Rotaready's customer success team will also continue to work closely with Pizza Pilgrims to ensure they're leveraging all the benefits of the platform, while feeding back to the engineering team to ensure the product remains as customer-centric as possible." />
              </Content>

              <Content>
                <Blockquote
                  text="One of the things we love about Rotaready is that the team is genuinely improving the product all the time. It's so refreshing to work with a software company that's proactive rather than reactive."
                  attribution="Sophie Gilchriest, Finance Director"
                />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_pizzapilgrims"
                label="rotaready.com/customer-stories/hospitality-pizza-pilgrims"
                postTo="marketing"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Pizza-Pilgrims.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/pizza-pilgrims/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/pizza-pilgrims/logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/pizza-pilgrims/inline1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/pizza-pilgrims/inline2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
